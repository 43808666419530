import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientService } from '@schneideress/za-ui-controls';
import { environment } from 'src/environments/environment';
import { IAccountUser } from '../types/account-user.interface';
import { INewUserInvitation } from '../types/new-user-invitation.interface';
import { IInvitationValidation } from '../types/invitation-validation.type';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AccessManagementService {
  constructor(
    private httpService: HttpClientService,
    private httpClient: HttpClient,
  ) {}

  validateInvitation(emailAddress: string, token: string): Observable<IInvitationValidation> {
    return this.httpService.post(
      environment.apiBaseUrl +
        `/access-management/invitations/${emailAddress}/validate?token=${token}`,
    );
  }

  declineInvitation(emailAddress: string, token: string): Observable<boolean> {
    return this.httpService.post(
      environment.apiBaseUrl +
        `/access-management/invitations/${emailAddress}/decline?token=${token}`,
    );
  }

  contactAdmin(emailAddress: string, token: string): Observable<boolean> {
    return this.httpService.post(
      environment.apiBaseUrl +
        `/access-management/invitations/${emailAddress}/contact-admin?token=${token}`,
    );
  }

  getAccountUsers(): Observable<IAccountUser[]> {
    return this.httpService.get(environment.apiBaseUrl + `/access-management/users`);
  }

  getAccountUser(userId: number): Observable<IAccountUser> {
    return this.httpService.get(environment.apiBaseUrl + `/access-management/users/${userId}`);
  }

  getAccountUserInvitation(emailAddress: string): Observable<IAccountUser> {
    return this.httpService.get(
      environment.apiBaseUrl + `/access-management/invitations/${emailAddress}`,
    );
  }

  deleteAccountUser(userId: number): Observable<boolean> {
    return this.httpService.delete(environment.apiBaseUrl + `/access-management/users/${userId}`);
  }

  deleteUserInvitation(emailAddress: string): Observable<boolean> {
    return this.httpService.delete(
      environment.apiBaseUrl + `/access-management/invitations/${emailAddress}`,
    );
  }

  updateAccountUser(user: IAccountUser): Observable<boolean> {
    return this.httpService.put(environment.apiBaseUrl + `/access-management/users`, user);
  }

  updateAccountUserInvitation(user: IAccountUser): Observable<boolean> {
    return this.httpService.put(environment.apiBaseUrl + `/access-management/invitations`, user);
  }

  createUserInvitation(invitation: INewUserInvitation): Observable<boolean> {
    return this.httpClient.post<boolean>(
      environment.apiBaseUrl + `/access-management/invitations`,
      invitation,
    );
  }

  checkIsUserInvited(): Observable<boolean> {
    return this.httpService.get(environment.apiBaseUrl + `/access-management/isUserInvited`);
  }
}
