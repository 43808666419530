import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { CompanyService } from './client.service';
import { SubscriptionType } from '../types/subscription-type';

type SubscriptionAccess = {
  type: SubscriptionType;
  mode: 'show' | 'hide' | 'disable';
};

@Directive({
  selector: '[s3SubscriptionAccess]',
})
export class SubscriptionAccessDirective {
  private subscriptionConditions: SubscriptionAccess[] = [];
  @Input()
  set s3SubscriptionAccess(value: SubscriptionAccess[]) {
    this.subscriptionConditions = value;
    this.initializeView();
  }

  constructor(
    private clientService: CompanyService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  private initializeView(): void {
    this.viewContainer.clear();
    this.clientService.getCurrentClient().subscribe(client => {
      this.viewContainer.createEmbeddedView(this.templateRef);
      const clientSubscription = client.Subscription?.SubscriptionType;
      if (!clientSubscription) {
        console.warn('Subscription type not found for client.');
        return;
      }
      // Find condition for the client subscription
      const subscriptionCondition = this.subscriptionConditions.find(
        x => x.type === clientSubscription,
      );
      // If condition does not exist, return
      if (!subscriptionCondition) return;
      // If condition exists with mode hide
      else if (subscriptionCondition.mode === 'hide') {
        this.viewContainer.clear();
      }
      // If condition exists with mode disable
      else if (subscriptionCondition.mode === 'disable') {
        const view = this.viewContainer.createEmbeddedView(this.templateRef);
        this.renderer.setAttribute(view.rootNodes[0], 'disabled', 'true');
      }
    });
  }
}
