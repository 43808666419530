import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClientService, TranslateService } from '@schneideress/za-ui-controls';
import { environment } from 'src/environments/environment';
import { Project } from '../models/project.model';
import { ProjectSite } from '../models/project-site.model';
import { ProjectType } from '../models/project-type.interface';

@Injectable({
  providedIn: 'root',
})
export class RoadmapService {
  constructor(
    private httpService: HttpClientService,
    private translateService: TranslateService,
  ) {}

  getProgress(): any {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/wizard/statistics');
  }

  getClientQuestionnire(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/questions/client');
  }

  getSiteQuestionnire(siteId: string): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/questions/site?siteGuid=' + siteId,
    );
  }

  getClientAnswers(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/answers/client');
  }

  getSiteAnswers(siteId: string): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/answers/site?siteId=' + siteId);
  }

  upsertAnswers(obj: any): Observable<boolean> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/answers', obj);
  }

  getDecarbonizationTarget(): Observable<boolean> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/decarbonizationTarget');
  }

  saveClientDecarbonizationRoadmap(): Observable<boolean> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/start/client', {});
  }

  getClientDecarbonizationRoadmap(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/client/details');
  }

  getAllSites(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/site/list');
  }

  getsitedetails(): any {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/decarbonization/completionPercentage',
    );
  }

  getProjectsByType(categoryId: number, projectId: any = null): Observable<any> {
    let url = environment.apiBaseUrl + '/roadmap/recommendedProjects?categoryId=' + categoryId;
    if (projectId != null) url += '&projectId=' + projectId;
    return this.httpService.get(url);
  }

  getAllProjectsByType(categoryId: number, projectId: any = null): Observable<any> {
    let url = environment.apiBaseUrl + '/roadmap/allProjects?categoryId=' + categoryId;
    if (projectId != null) url += '&projectId=' + projectId;
    return this.httpService.get(url);
  }

  getProjectListByCategory(categoryId: number): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/projects/category?categoryId=' + categoryId,
    );
  }

  getUtilities(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/common/Utilities?siteId=' + siteId);
  }

  getSiteDetails(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/site?siteId=' + siteId);
  }

  getEmission(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/myRoadmap/emission');
  }

  getSolutionProvider(siteId: number, hierLevel: string): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/providers?siteId=' + siteId + '&hierLevel=' + hierLevel,
    );
  }

  getSolutionProviders(
    projectTypeId: number | undefined = undefined,
    sitedetails: any[] | undefined,
  ): Observable<any> {
    let url = environment.apiBaseUrl + '/roadmap/providersByProjectType';
    if (projectTypeId != undefined) url += '?projectTypeId=' + projectTypeId;
    return this.httpService.post(url, sitedetails);
  }

  updateProjectStatus(statusInfo: any): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/project/status', statusInfo);
  }

  getDataStreamsBySiteId(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/dataStream?siteId=' + siteId);
  }

  getProjectTypes(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/project/details');
  }

  getSiteLevelProjectTypes(): Observable<ProjectType[]> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/projectTypes/site').pipe(
      map((response: any[]) => {
        return response.map((item: any) => {
          return {
            ...item,
            DisplayText: this.translateService.translate('shared.' + item.ProjectTypeKey),
          };
        });
      }),
    );
  }

  saveProjectInformation(projectObj: object): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/projectinfo', projectObj);
  }

  saveProject(projectObj: object): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/project', projectObj);
  }

  saveProjectSite(projectSiteObj: object): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/projectsite', projectSiteObj);
  }

  saveSiteDecarbonizationroadmap(siteDecarbonizationRoadmapInfo: any): Observable<any> {
    return this.httpService.post(
      environment.apiBaseUrl + '/roadmap/siteDecarbonizationRoadmapInfo',
      siteDecarbonizationRoadmapInfo,
    );
  }

  getSiteDecarboniztionDetails(siteId: number): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/siteDecarbonizationRoadmapInfo/site?siteId=' + siteId,
    );
  }

  getProjectInformation(clientHierRecommendedProjectId: number): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl +
        '/roadmap/projectinformation?clientHierRecommendedProjectId=' +
        clientHierRecommendedProjectId,
    );
  }

  getProject(projectId: number): Observable<Project> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/project?projectId=' + projectId);
  }

  getProjectSite(projectId: number, siteId: number): Observable<ProjectSite> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/projectsite?projectId=' + projectId + '&siteId=' + siteId,
    );
  }

  getRecomendedProjects(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/recommendedProjects/categorized',
    );
  }

  getIncompleteSitesDecarbonization(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/incompleteSites');
  }

  generateRoadMap(): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/roadmap/wizard', {});
  }

  getSiteSummary(siteId: string): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/wizard/site/summary?siteId=' + siteId,
    );
  }

  getCompanySummary(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/wizard/client/summary');
  }

  getRecommendedProjectByFleet(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/renewable/recomendation/fleet');
  }

  getEmissionForType(scope: string): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/renewable/emission/' + scope);
  }

  getRecommendedProjectsByGreenTariffUS(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/renewable/recomendation/greentariff/us',
    );
  }

  getRecommendedProjectsByGreenTariffEU(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/renewable/recomendation/greentariff/eu',
    );
  }

  getRecommendedProjectsByGreenTariffOutsideUSandEU(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/renewable/recomendation/greentariff/outsideuseu',
    );
  }

  getRecommendedProjectsByPowerPurchaseAgreement(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/renewable/recomendation/greentariff/ppa',
    );
  }

  getRecommendedProjectsByOnsiteSolar(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/renewable/recomendation/greentariff/onsitesolar',
    );
  }

  getAllProjectsByOnsiteSolar(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/renewable/projects/onsitesolar');
  }

  getRecommendedProjectsByEnergyAttributeCertificates(): Observable<any> {
    return this.httpService.get(
      environment.apiBaseUrl + '/roadmap/renewable/recomendation/greentariff/eac',
    );
  }

  getAllProjectsByEnergyAttributeCertificates(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/renewable/projects/eac');
  }

  getAllProjectByMicrogrid(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/renewable/projects/microgrid');
  }

  translateQuestions(data: any, siteDetails: any): any {
    data.forEach((step: any) => {
      step.questionGroups.forEach((item: any) => {
        item.questions.forEach((question: any) => {
          if (question.label) {
            question.label = this.translateService.translate('shared.' + question.label);
            question.label = question.label.replace(/<([^>]+)>/g, siteDetails?.SiteName);
          }
          if (question.helpText && question.helpText !== '')
            question.helpText = this.translateService.translate('shared.' + question.helpText);
          if (question.dataSource) {
            if (question.dataSource.data && question.dataSource.data.length > 0) {
              question.dataSource.data.forEach((dataSource: any) => {
                if (dataSource.text)
                  dataSource.text = this.translateService.translate('shared.' + dataSource.text);
              });
            }
          }
        });
      });
    });
    return data;
  }
}
